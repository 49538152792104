<template>
  <div>
    <About ref="about"/>
    <b-navbar class="bg-custom">
      <b-navbar-nav>
        <b-dropdown menu-class="pr-2" title="Functions" size="sm" left>
          <template #button-content>
            <feather type="menu"></feather>
          </template>
          <b-dropdown-item to="worklist"><feather type="inbox"></feather> Worklist</b-dropdown-item>
          <b-dropdown-item to="submit_scan_dicom"><feather type="upload-cloud"></feather> Submit Scan (DICOM)</b-dropdown-item>
          <b-dropdown-item v-if="canUploadNifti" to="submit_scan_nifti"><feather type="upload-cloud"></feather> Submit Scan (NifTI-1)</b-dropdown-item>
          <b-dropdown-item @click="userSettings()"><feather type="settings"></feather> User Settings</b-dropdown-item>
        </b-dropdown>
      </b-navbar-nav>
      <b-navbar-brand href="#" @click="about">
        <img src="../assets/corinsightsmri.png" class="ml-4 d-inline-block align-top" alt="Saince" height="30">
      </b-navbar-brand>
      <b-navbar-nav>
        <b-nav-text class="text-white" >{{version}}</b-nav-text>
      </b-navbar-nav>
      <b-navbar-nav>
        <b-nav-text class="ml-4 text-white" >User: {{user}}</b-nav-text>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav>
          <b-nav-item to="logout" link-classes="text-white"><feather type="log-out"></feather> Sign Out</b-nav-item>
          <b-nav-item to="help" link-classes="text-white"><feather type="help-circle"></feather> Help </b-nav-item>
        </b-nav>
      </b-navbar-nav>
    </b-navbar>
  </div>  
</template>
<script>
import axios from 'axios'
import About from './About.vue'
import roles from '../common/roles'

export default {
  name: 'dashboardNavBar',
  components: {
    About
  },
  data() {
    return {
      version: "vTBD"
    }
  },
  mounted() {
    axios.get(process.env.BASE_URL+"VERSION.txt")
    .then(response => {
      this.$log.debug("Loaded VERSION.txt");
      var versionArray = response.data.split("\n")
      if (versionArray.length > 1) {
        this.version = "v"+versionArray[1].replace(/.*Version: /, '')
      }
    })
    .catch(err => {
      this.$log.error("Error retrieving VERSION.txt: "+err.message)
    })
  },
  computed: {
    canUploadNifti() {
      return roles.hasRole(roles.ROLE_CAN_UPLOAD_NIFTI)
    },
    user() {
      return this.$store.state.userProfile.username
    }
  },
  methods: {
    about() {
      this.$refs.about.show()
    },
    userSettings() {
      try {
        this.$store.state.keycloak.accountManagement()
      }
      catch(err) {
        this.$log.error('Failed to open account profile: '+err);
      }
    }
  }
}    
</script>
<style scoped>
.bg-custom {
  background-color: #1c2a48
}
.bg-custom2 {
  background-color: #3b465e
}
</style>
